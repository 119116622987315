import '../css/aui.scss';
import '../css/style_load.scss';
import '../css/tracking.scss';

import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PortalThemeProvider } from '@pochta/ui/themes/portal';
import LandingPage from './landing/LandingPage';
import MainSupportPage from './support/MainSupportPage';
import SpecificationPage from './specification/SpecificationPage';
import StatisticsPage from './statistics/StatisticsPage';
import SettingsPage from './settings/SettingsPage';
import MainError from './error/MainError';
import CommonContextProvider from '../provider/CommonContextProvider';

const App = () => {
  return (
    <PortalThemeProvider>
      <BrowserRouter>
        <CommonContextProvider>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/support" component={MainSupportPage} />
            <Route path="/statistics" component={StatisticsPage} />
            <Route path="/access-settings" component={SettingsPage} />
            <Route path="/specification" component={SpecificationPage} />
            <Route component={MainError} />
          </Switch>
        </CommonContextProvider>
      </BrowserRouter>
    </PortalThemeProvider>
  );
};

export default App;
