import { ICurrentMenuItem, IMenuItem, ISupportMenu, TBreadcrumbs } from '../interfaces';

export const isSameUrl = (url: string): boolean => {
  try {
    const { location } = window;
    if (url.includes('http')) {
      const { hostname, pathname } = new URL(url);
      return pathname === location.pathname && hostname === location.hostname;
    }
    return url.replace(/\/$/, '') === (location.pathname + location.hash).replace(/\/$/, '');
  } catch (error) {
    return false;
  }
};

export const getScrollbarWidth = (): number => {
  if (typeof document === 'undefined') {
    return 0;
  }

  const scrollDiv = document.createElement('div');
  scrollDiv.setAttribute('style', 'width: 50px, height: 50px; overflow: scroll; position: absolute; top: -9999999px;');
  document.body.append(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  scrollDiv.remove();
  return scrollbarWidth;
};

export const getCurrentList = (menu: ISupportMenu, currentItem: ICurrentMenuItem): IMenuItem | undefined => {
  if (!currentItem || !menu) {
    return undefined;
  }
  const { sectionIndex, listIndex } = currentItem;
  if (!Number.isInteger(listIndex)) return undefined;
  // @ts-ignore
  return menu[sectionIndex].items[listIndex];
};

export const getCurrentMenuItem = (menu: ISupportMenu): ICurrentMenuItem | undefined => {
  let currentItem: ICurrentMenuItem | undefined;
  menu.some((section, sectionIndex) => {
    return section.items?.some((list, listIndex) => {
      if (list.link && isSameUrl(list.link) && !currentItem) {
        currentItem = {
          title: list.title,
          link: list.link,
          sectionIndex,
          listIndex,
          isList: true,
        };
      }
      return list.items?.some((item, itemIndex) => {
        if (item.link && isSameUrl(item.link)) {
          currentItem = {
            ...item,
            sectionIndex,
            listIndex,
            itemIndex,
          };
          return true;
        }
        return false;
      });
    });
  });
  return currentItem;
};

export const getBreadcrumbs = (menu: ISupportMenu, currentItem: ICurrentMenuItem, skipList = false): TBreadcrumbs => {
  const items: TBreadcrumbs = [{ title: 'Помощь', link: '/support/faq' }];
  if (currentItem) {
    const { sectionIndex, listIndex } = currentItem;
    if (Number.isInteger(sectionIndex)) {
      // @ts-ignore
      const section = menu[tabIndex].items[sectionIndex];
      if (section.title && section.title.toLowerCase() !== 'common') {
        items.push({ title: section.title });
      }

      if (!skipList && Number.isInteger(listIndex)) {
        const list = section.items[listIndex];
        items.push({ title: list.title, link: list.link });
      }
    }
  }
  return items;
};
