import './statistics.scss';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import NotificationSettings from './NotificationSettings';
import StatisticsChart from './StatisticsChart';
import ContractState from './ContractState';
import services from '../../service/services';
import { ErrorDto, ModeType, StatisticsDto } from '../../service/api-dtos';
import withCommonContext from '../../hoc/withCommonContext';
import { CommonContextProps } from '../../provider/CommonContextProvider';

type Props = WithTranslation &
  RouteComponentProps &
  CommonContextProps & {
    mode: ModeType;
  };

type State = {
  statistics: StatisticsDto;
};

class StatisticsForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      statistics: {
        userEmail: '',
        unlimited: false,
        data: [],
        dataBatch: [],
        settings: {
          sendStatistics: false,
          notifyEvery: 1,
          notifyOverLimits: false,
        },
        batchSettings: {
          sendStatistics: false,
          notifyEvery: 1,
        },
      },
    };
    this.settingsChanged = this.settingsChanged.bind(this);
    this.settingsBatchChanged = this.settingsBatchChanged.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onCheckSend = this.onCheckSend.bind(this);
    this.onCheckNotifyOverLimits = this.onCheckNotifyOverLimits.bind(this);
    this.onSelectBatch = this.onSelectBatch.bind(this);
    this.onCheckSendBatch = this.onCheckSendBatch.bind(this);
  }

  componentDidMount() {
    const { showErrorNotification, history } = this.props;
    services.apiControl
      .getStatistics()
      .then((result: StatisticsDto) => {
        this.setState({
          statistics: {
            ...{
              dataBatch: [],
              batchSettings: { sendStatistics: false, notifyEvery: 1 },
            },
            ...result,
          },
        });
      })
      .catch((error: ErrorDto) => {
        showErrorNotification(error, 'getAccessSettings', (errorType) => {
          if (errorType === 'USER_UNAUTHORIZED' || errorType === 'PORTAL_BACKEND_USER_NOT_FOUND') {
            history.push('/');
          }
        });
      });
  }

  onSelect(data: number) {
    this.setState(
      (prevState: State) => ({
        statistics: {
          ...prevState.statistics,
          settings: {
            ...prevState.statistics.settings,
            notifyEvery: data,
          },
        },
      }),
      () => this.settingsChanged(),
    );
  }

  onCheckSend(checked: boolean) {
    this.setState(
      (prevState: State) => ({
        statistics: {
          ...prevState.statistics,
          settings: {
            ...prevState.statistics.settings,
            sendStatistics: checked,
          },
        },
      }),
      () => this.settingsChanged(),
    );
  }

  onCheckNotifyOverLimits(checked: boolean) {
    this.setState(
      (prevState: State) => ({
        statistics: {
          ...prevState.statistics,
          settings: {
            ...prevState.statistics.settings,
            notifyOverLimits: checked,
          },
        },
      }),
      () => this.settingsChanged(),
    );
  }

  onSelectBatch(data: number) {
    this.setState(
      (prevState: State) => ({
        statistics: {
          ...prevState.statistics,
          batchSettings: {
            ...prevState.statistics.batchSettings,
            notifyEvery: data,
          },
        },
      }),
      () => this.settingsBatchChanged(),
    );
  }

  onCheckSendBatch(checked: boolean) {
    this.setState(
      (prevState: State) => ({
        statistics: {
          ...prevState.statistics,
          batchSettings: {
            ...prevState.statistics.batchSettings,
            sendStatistics: checked,
          },
        },
      }),
      () => this.settingsBatchChanged(),
    );
  }

  settingsChanged() {
    const { statistics } = this.state;
    const { showErrorNotification } = this.props;
    services.apiControl.saveSettings(statistics.settings).catch((error: ErrorDto) => {
      showErrorNotification(error, 'saveSettings');
    });
  }

  settingsBatchChanged() {
    const { statistics } = this.state;
    const { showErrorNotification } = this.props;
    services.apiControl.saveSettingsBatch(statistics.batchSettings).catch((error: ErrorDto) => {
      showErrorNotification(error, 'saveSettingsBatch');
    });
  }

  render() {
    const { t, mode } = this.props;
    const { statistics } = this.state;
    const { userEmail, unlimited, data, settings, dataBatch, batchSettings } = statistics;

    return (
      <div className="tracking-page__content">
        <div className="statistics-form">
          {unlimited && <div className="statistics-form__header">{t(`left-menu.text-${mode}-access`)}</div>}
          <div className="statistics-form__paper">
            <StatisticsChart mode={mode} unlimitedAccessAvailable={unlimited} data={data} dataBatch={dataBatch} />
            <hr className="statistics-form__delimiter" />
            <NotificationSettings
              mode={mode}
              unlimitedAccessAvailable={unlimited}
              onSelect={this.onSelect}
              onCheckSend={this.onCheckSend}
              onCheckNotifyOverLimits={this.onCheckNotifyOverLimits}
              onSelectBatch={this.onSelectBatch}
              onCheckSendBatch={this.onCheckSendBatch}
              settings={settings}
              batchSettings={batchSettings}
              email={userEmail}
            />
          </div>
          <ContractState unlimited={statistics.unlimited} />
        </div>
      </div>
    );
  }
}

export default withTranslation()(withCommonContext(withRouter(StatisticsForm)));
