import React, { useState, useCallback, useEffect, PropsWithChildren, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { defaultUserInfo, ErrorDto, ErrorDtoType, UserInfo } from '../service/api-dtos';
import services from '../service/services';
import { urls } from '../service/constants';

export interface CommonContextProps {
  userInfo: UserInfo;
  isShowNotification: boolean;
  message: string;
  closeNotification: () => void;
  showNotification: (message: string, onClose?: () => any) => void;
  showErrorNotification: (error: ErrorDto, service: string, onClose?: (errorType?: ErrorDtoType) => any) => void;
}

export const CommonContext = createContext<CommonContextProps>({
  userInfo: defaultUserInfo,
  isShowNotification: false,
  message: '',
  closeNotification: () => {},
  showNotification: () => {},
  showErrorNotification: () => {},
});

const CommonContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const history = useHistory();
  const { t } = useTranslation<string>();
  const [userInfo, setUserInfo] = useState<UserInfo>(defaultUserInfo);
  const [isShowNotification, setIsShowNotification] = useState(false);
  const [message, setMessage] = useState('');
  const [errorType, setErrorType] = useState<ErrorDtoType>();
  const [onCloseNotification, setOnCloseNotification] = useState<(errorType?: ErrorDtoType) => any>();

  useEffect(() => {
    services.apiControl
      .getUserInfo()
      .then((result: UserInfo) => {
        setUserInfo(result);
      })
      .catch(() => {});
  }, []);

  const showErrorNotification = useCallback(
    (error: ErrorDto, service: string, onClose?: (errorType?: ErrorDtoType) => any) => {
      switch (error.type) {
        case 'INCOMPLETE_POST_ID_USER_PROFILE':
          history.push('/incomplete-profile');
          break;
        case 'SERVICE_UNAVAILABLE':
          history.push('/503');
          break;
        case 'USER_UNAUTHORIZED':
          window.location.href = urls.LOGIN_URL;
          break;
        default:
          setIsShowNotification(navigator.userAgent !== 'ReactSnap');
          setMessage(t(error.messageKey));
          setOnCloseNotification(onClose);
          setErrorType(error.type);
          break;
      }
    },
    [history, t],
  );

  const showNotification = useCallback((notificationMessage: string, onClose?: () => any) => {
    setIsShowNotification(true);
    setMessage(notificationMessage);
    setOnCloseNotification(onClose);
  }, []);

  const closeNotification = useCallback(() => {
    setIsShowNotification(false);
    if (onCloseNotification) {
      onCloseNotification(errorType);
    }
  }, [errorType, onCloseNotification]);

  const contextValue: CommonContextProps = {
    userInfo,
    isShowNotification,
    message,
    closeNotification,
    showNotification,
    showErrorNotification,
  };

  return <CommonContext.Provider value={contextValue}>{children}</CommonContext.Provider>;
};

export default CommonContextProvider;
