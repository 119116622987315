import React from 'react';
import { Link } from 'react-router-dom';

const ServiceForWhoEn = () => (
  <div className="help-page">
    <Link to="/support/faq">Frequently Asked Questions</Link>
    <h3> For whom is API service convenient?</h3>
    <article className="page-help-article__content">
      <p>
        API is designed for online stores and large-scale senders who need to track delivery and use this information in their information systems.
      </p>
      <p>&nbsp;</p>
      <p>
        If you need to track only one or more parcels, you should better use the&ensp;<a href="http://pochta.ru">main site</a>&ensp;of Russian Post
        or&ensp;<a href="https://pochta.ru/support/web-mobile-services/mobile-application">mobile application</a>&ensp;of Russian Post.
      </p>
    </article>
  </div>
);

export default ServiceForWhoEn;
