import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { Ul } from '@pochta/ui';
import styled, { css } from 'styled-components';
import cn from 'classnames';
import { ICurrentMenuItem, IMenuItem } from './interfaces';

const lineBaseCss = css`
  position: relative;
  ::before {
    content: '';
    display: block;
    width: 1px;
    position: absolute;
    left: -16px;
  }
`;

const InnerList = styled(Ul)`
  margin-left: 56px;
  ${lineBaseCss}
  ::before {
    height: calc(100% - 0.5em);
    background-color: ${({ theme }): string => theme.palette.neutralsBlack.rgba20};
  }

  li.active {
    ${lineBaseCss}
    ::before {
      height: calc(100% - 8px);
      background-color: ${({ theme }): string => theme.palette.newBlue.hex50};
    }
    &:last-child::before {
      height: 100%;
    }
  }
`;

interface Props {
  items: IMenuItem['items'];
  currentItem?: ICurrentMenuItem;
}

const Items: FC<Props> = ({ items, currentItem }: Props) => {
  return (
    <InnerList listStyleType="none" ml={30} pt={16}>
      {items &&
        items.map((item, itemIndex) => {
          const isActive = currentItem?.itemIndex === itemIndex;
          return (
            <Ul.Li key={item.link} className={cn({ active: isActive })}>
              <Link to={item.link}>{item.title}</Link>
            </Ul.Li>
          );
        })}
    </InnerList>
  );
};

export default memo(Items);
