import './tracking-setting.scss';

import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import services from '../../service/services';
import Info from './Info';
import { AccessSettingsDto, ErrorDto } from '../../service/api-dtos';
import withCommonContext from '../../hoc/withCommonContext';
import { getUrlParams } from '../../common/helper';
import { CommonContextProps } from '../../provider/CommonContextProvider';

type Props = WithTranslation & RouteComponentProps & CommonContextProps;

type State = {
  accessSettings: AccessSettingsDto;
};

class TrackingSettings extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      accessSettings: {
        userEmail: '',
        backendUserLogin: '',
        isBatchAccessAllowed: false,
      },
    };
    this.showPasswordSentNotification = this.showPasswordSentNotification.bind(this);
    this.showSentSettingsNotification = this.showSentSettingsNotification.bind(this);
    this.sendSettings = this.sendSettings.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  componentDidMount() {
    const { history, showErrorNotification, location } = this.props;
    services.apiControl
      .getAccessSettings()
      .then((result: AccessSettingsDto) => {
        this.setState({ accessSettings: result });
      })
      .catch((error: ErrorDto) => {
        showErrorNotification(error, 'getAccessSettings', (errorType) => {
          if (errorType === 'USER_UNAUTHORIZED' || errorType === 'PORTAL_BACKEND_USER_NOT_FOUND') {
            history.push('/');
          }
        });
      });

    const urlParams = getUrlParams(location.search);
    if (urlParams.showNotification === 'true') {
      this.showSentSettingsNotification();
    }
  }

  showPasswordSentNotification() {
    const { t, showNotification, closeNotification } = this.props;
    const { accessSettings } = this.state;
    showNotification(t('settings.tracking.new-password-sent', { email: accessSettings.userEmail }));
    setTimeout(() => {
      closeNotification();
    }, 10000);
  }

  showSentSettingsNotification() {
    const { t, showNotification, closeNotification } = this.props;
    const { accessSettings } = this.state;
    showNotification(t('settings.tracking.access-data-sent', { email: accessSettings.userEmail }));
    setTimeout(() => {
      closeNotification();
    }, 10000);
  }

  sendSettings() {
    const { showErrorNotification } = this.props;
    services.apiControl
      .sendSettings()
      .then(() => {
        this.showSentSettingsNotification();
      })
      .catch((error: ErrorDto) => {
        showErrorNotification(error, 'sendSettings');
      });
  }

  resetPassword() {
    const { showErrorNotification } = this.props;
    services.apiControl
      .resetPassword()
      .then(() => {
        this.showPasswordSentNotification();
      })
      .catch((error: ErrorDto) => {
        showErrorNotification(error, 'resetPassword');
      });
  }

  render() {
    const { t } = this.props;
    const { accessSettings } = this.state;
    return (
      <div className="tracking-page__content">
        <div className="tracking-page__content-header">{t('settings.tracking.info.title')}</div>
        <div className="tracking-setting">
          <Info
            login={accessSettings.backendUserLogin}
            isBatchAccessAllowed={accessSettings.isBatchAccessAllowed}
            onSendSettings={this.sendSettings}
            onResetPassword={this.resetPassword}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(withCommonContext(withRouter(TrackingSettings)));
