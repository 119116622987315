import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Language } from '../../../common/types';

type Props = {
  isMainPage?: boolean;
};

const Footer = ({ isMainPage = true }: Props) => {
  const { t, i18n } = useTranslation<string>();
  const [currentYear] = useState(new Date().getFullYear());

  const setLanguage = (lang: Language) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className={cn('footer-container', 'footer-container--white')}>
      <div className={cn('footer', { 'footer--hidden': isMainPage })}>
        <div className="footer__copyright">
          {currentYear}
          {t('russianpost.theme.footer')}
        </div>
        <div className="footer__menu">
          <div className="footer__language">
            {i18n.language === 'en' && (
              <span className="taglib-language-list-text" role="button" tabIndex={0} onClick={() => setLanguage('ru')}>
                Русский
              </span>
            )}
            {i18n.language === 'ru' && (
              <span className="taglib-language-list-text" role="button" tabIndex={0} onClick={() => setLanguage('en')}>
                English
              </span>
            )}
          </div>
          <Link to="/support/faq" className="footer__menu-button">
            {t('russianpost.theme.support')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
