import React from 'react';
import { Link } from 'react-router-dom';

const HowToGetAccessRu = () => (
  <div className="help-page">
    <Link to="/support/faq">Часто задаваемые вопросы</Link>
    <h3> Как получить доступ?</h3>
    <article className="page-help-article__content">
      <p>Инструкция по подключению к Сервису отслеживания отправлений:</p>
      <p>&nbsp;</p>
      <p>
        1) Перейдите на&ensp;<Link to="/">главную страницу</Link>&ensp;сервиса отслеживания.
      </p>
      <p>2) Нажмите кнопку &quot;Получить доступ&quot;.</p>
      <p>
        3) Если вы уже регистрировались на&ensp;<a href="http://pochta.ru">основном портале Почты</a>&ensp;- войдите с использованием логина и пароля.
        Если нет - пройдите короткую процедуру регистрации.
      </p>
      <p>3) Согласитесь с условиями использования сервиса.</p>
      <p>
        4) Доступ предоставлен. На указанный адрес электронной почты отправлены данные для подключения. Они также доступны в разделе&ensp;
        <Link to="/access-settings">Настройки доступа</Link>.
      </p>
      <p>5) Начните вызывать методы API из вашей информационной системы, используя полученные адрес сервиса, логин и пароль.</p>
      <p>
        6) В разделе&ensp;<Link to="/statistics">Статистика</Link>&ensp;вы можете просматривать статистику вашего доступа и настраивать уведомления.
      </p>
    </article>
  </div>
);

export default HowToGetAccessRu;
