import React from 'react';
import { Link } from 'react-router-dom';

const NoStatisticsDataRu = () => (
  <div className="help-page">
    <Link to="/support/faq">Часто задаваемые вопросы</Link>
    <h3> Почему я не вижу данных статистики за сегодня?</h3>
    <article className="page-help-article__content">
      Данные статистики обновляются один раз в сутки, информация по обращениям к API Сервиса отслеживания за сегодняшний день будет доступна завтра.
    </article>
  </div>
);

export default NoStatisticsDataRu;
