import React from 'react';
import { Link } from 'react-router-dom';

const ServiceForWhoRu = () => (
  <div className="help-page">
    <Link to="/support/faq">Часто задаваемые вопросы</Link>
    <h3> Для кого предназначен API Сервиса отслеживания?</h3>
    <article className="page-help-article__content">
      <p>
        API предназначен для интернет-магазинов и крупных отправителей, которым необходимо отслеживать отправления и использовать эту информацию в
        своих информационных системах.
      </p>
      <p>&nbsp;</p>
      <p>
        Если вам нужно просто отследить одну или несколько посылок, воспользуйтесь&ensp;<a href="http://pochta.ru">основным порталом</a>
        &ensp;или&ensp;
        <a href="https://pochta.ru/support/web-mobile-services/mobile-application">мобильным приложением</a>&ensp;Почты России.
      </p>
    </article>
  </div>
);

export default ServiceForWhoRu;
