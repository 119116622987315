import React, { FC, memo, useMemo } from 'react';
import { Box, useSsr } from '@pochta/ui';
import styled, { css } from 'styled-components';
import { getScrollbarWidth } from './helpers/menu-helper';
import { ICurrentMenuItem, ISupportMenu } from './interfaces';
import Section from './Section';

const MenuWrapper = styled(Box)<{ multilevel: boolean; scrollbarWidth: number }>`
  ${({ multilevel }) =>
    multilevel &&
    css`
      max-height: 100vh;
      overflow: auto;
      padding-right: 25px;
    `}
  ${({ multilevel, scrollbarWidth }) =>
    !!scrollbarWidth &&
    multilevel &&
    css`
      scrollbar-width: thin;
      scrollbar-color: #e6e6e8 #fff;

      &::-webkit-scrollbar {
        background-color: #fff;
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: #fff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #e6e6e8;
        border-radius: 8px;
        border: 2px solid #fff;
      }

      &::-webkit-scrollbar-button {
        display: none;
      }
    `}
`;

interface Props {
  menu: ISupportMenu;
  currentItem?: ICurrentMenuItem;
  multilevel?: boolean;
}

const SupportMenu: FC<Props> = ({ menu, currentItem, multilevel = false }: Props) => {
  const { isSsr } = useSsr();
  const scrollbarWidth = useMemo(() => {
    return isSsr ? 0 : getScrollbarWidth();
  }, [isSsr]);

  return (
    menu && (
      <Box flexbox flexDirection="column">
        <MenuWrapper multilevel={multilevel} scrollbarWidth={scrollbarWidth}>
          {menu &&
            menu.map((section, index) => {
              return (
                <Section key={`key_${section.title}`} section={section} sectionIndex={index} multilevel={multilevel} currentItem={currentItem} />
              );
            })}
        </MenuWrapper>
      </Box>
    )
  );
};

export default memo(SupportMenu);
