import React from 'react';
import { Link } from 'react-router-dom';

const SupportAboutEn = () => (
  <div className="help-page row-fluid">
    <div>
      <header>
        <h1>Support</h1>
        <p>
          If you do not find the answer to your question — please contact support by phone Russian Post <b>8-800-100-00-00</b> or email at&ensp;
          <a href="mailto:client@russianpost.ru">client@russianpost.ru</a>
        </p>
      </header>
      <article>
        <h2 id="about">On the service</h2>
        <p>
          <Link to="/support/about/agreement">User agreement</Link>
        </p>
        <p>
          <Link to="/support/about/examples">Sample code to use API service</Link>
        </p>
        <p>
          <Link to="/support/about/testing_access">How to check access?</Link>
        </p>
      </article>
      <footer>
        <p>If you do not find the answer to your question - please contact support by phone Russian Post</p>
        <h2>
          <b>8-800-100-00-00</b> or&ensp;<a href="mailto:client@russianpost.ru">client@russianpost.ru</a>
        </h2>
      </footer>
    </div>
  </div>
);

export default SupportAboutEn;
