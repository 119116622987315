import './styles.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LandingMessages = () => {
  const { t } = useTranslation<string>();

  return (
    <div className="landing-page__content">
      <div className="landing-content" dangerouslySetInnerHTML={{ __html: t('LandingMessages.leftColumn') }} />
      <div className="landing-content" dangerouslySetInnerHTML={{ __html: t('LandingMessages.middleColumn') }} />
      <div className="landing-content" dangerouslySetInnerHTML={{ __html: t('LandingMessages.rightColumn') }} />
    </div>
  );
};

export default LandingMessages;
