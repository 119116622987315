import React, { useMemo } from 'react';
import { Box } from '@pochta/ui';
import { useTranslation } from 'react-i18next';
import useCommonContext from '../../../hook/useCommonContext';
import SupportMenu from './SupportMenu';
import { getCurrentMenuItem } from './helpers/menu-helper';
import leftNavigation from './leftNavigation';
import { ILeftMenuItem, ISupportMenu } from './interfaces';

const LeftMenu = () => {
  const { t } = useTranslation<string>();
  const { userInfo } = useCommonContext();
  const { isServiceTrackingUser, isUnlimited } = userInfo;

  const menu: ISupportMenu = useMemo(() => {
    const extractSupportMenu = (items: ILeftMenuItem[]): ISupportMenu => {
      return items
        .filter((item: ILeftMenuItem) => {
          return (
            !(item.forServiceTrackingUser || item.forUnlimitedUser) ||
            (item.forServiceTrackingUser && isServiceTrackingUser) ||
            (item.forUnlimitedUser && isUnlimited)
          );
        })
        .map((item: ILeftMenuItem) => {
          return {
            title: item.nameKey ? t(item.nameKey) : undefined,
            link: item.link,
            items: item.items ? extractSupportMenu(item.items) : undefined,
          };
        });
    };
    return extractSupportMenu(leftNavigation);
  }, [t, isServiceTrackingUser, isUnlimited]);

  const currentItem = getCurrentMenuItem(menu);

  return (
    <Box
      width={{
        xs: 'calc(30% + 2*26px)',
        md: 'calc(300px + 2*26px)',
      }}
      flexShrink={0}
      mr={26}
      borderRight="1px solid #CCC"
    >
      <Box position="sticky" top="0">
        <SupportMenu menu={menu} currentItem={currentItem} multilevel />
      </Box>
    </Box>
  );
};

export default LeftMenu;
