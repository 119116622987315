import TMenu from '@portal/portal-theme-v2/dist/typings/packages/portal-theme-v2/src/js/interfaces/TMenu';

/** load JSON from https://www.pochta.ru/api/content/v1.0/navigation */
const mainNavigation: TMenu = [
  {
    title: 'Частным лицам',
    openInNewTab: false,
    openInNewWindow: false,
    items: [
      {
        title: 'Отправить',
        openInNewTab: false,
        openInNewWindow: false,
        items: [
          {
            title: 'Письмо или бандероль',
            link: 'https://www.pochta.ru/letters',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Посылку',
            link: 'https://www.pochta.ru/parcels',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Массовая отправка посылок',
            link: 'https://www.pochta.ru/bulk-upload',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Телеграмму',
            link: 'https://telegramma.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Электронное заказное письмо',
            link: 'https://zakaznoe.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Вызвать курьера',
            link: 'https://www.pochta.ru/courier',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Виды отправлений',
            link: 'https://www.pochta.ru/support/post-rules/sending-types',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Почтовые услуги и правила',
            link: 'https://www.pochta.ru/support#post-rules',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Почтовые бланки',
            link: 'https://www.pochta.ru/forms-list',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Индексы',
            link: 'https://www.pochta.ru/post-index',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
        ],
      },
      {
        title: 'Получить',
        openInNewTab: false,
        openInNewWindow: false,
        items: [
          {
            title: 'Отслеживание',
            link: 'https://www.pochta.ru/tracking',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Упрощенное получение отправлений',
            link: 'https://passport.pochta.ru/activate/ops',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Подписка на издания',
            link: 'https://podpiska.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Получение отправлений',
            link: 'https://www.pochta.ru/support/post-rules/receiving-sending',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Аренда абонентского ящика',
            link: 'https://abox.pochta.ru/cabinet/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Отделения почтовой связи',
            link: 'https://www.pochta.ru/offices',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Курьерская служба EMS',
            link: 'https://www.pochta.ru/emspost/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Поиск пропавших отправлений',
            link: 'https://www.pochta.ru/support/claims/tracking/lost-sendings',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Электронное обращение',
            link: 'https://www.pochta.ru/claim',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Выдать посылку другому человеку',
            link: 'https://www.pochta.ru/elektronaya-doverenost',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
        ],
      },
      {
        title: 'Платежи и переводы',
        openInNewTab: false,
        openInNewWindow: false,
        items: [
          {
            title: 'Денежные переводы и оплата услуг',
            link: 'https://www.pochta.ru/finance',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Почтовый перевод',
            link: 'https://www.pochta.ru/support/money-transfer/mail-transfer',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Перевод Форсаж',
            link: 'https://www.pochta.ru/support/money-transfer/forsage',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Перевод Western Union',
            link: 'https://www.pochta.ru/westernunion',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'ПочтаБанк',
            link: 'https://www.pochtabank.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
        ],
      },
      {
        title: 'Услуги в отделениях',
        openInNewTab: false,
        openInNewWindow: false,
        items: [
          {
            title: 'Подписка на периодические издания',
            link: 'https://www.pochta.ru/support/subscription/common-info',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Пенсии и пособия',
            link: 'https://www.pochta.ru/support/office-services/pensions-and-benefits',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Прием платежей',
            link: 'https://www.pochta.ru/support/money-transfer/common-info',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Лотерейные билеты',
            link: 'https://www.pochta.ru/support/office-services/lottery-tickets',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Филателия',
            link: 'https://www.pochta.ru/support/office-services/filatelia',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Аренда абонентского ящика',
            link: 'https://www.pochta.ru/support/office-services/post-office-box-rent',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Билеты на транспорт',
            link: 'https://www.pochta.ru/support/office-services/transport-tickets',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Остальные услуги',
            link: 'https://www.pochta.ru/support/office-services/services',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
        ],
      },
      {
        title: 'Онлайн-сервисы',
        openInNewTab: false,
        openInNewWindow: false,
        items: [
          {
            title: 'Отследить отправление',
            link: 'https://www.pochta.ru/tracking',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Отправить письмо',
            link: 'https://www.pochta.ru/letters',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Отправить посылку',
            link: 'https://www.pochta.ru/parcels',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Вызвать курьера',
            link: 'https://www.pochta.ru/courier',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Мобильное приложение',
            link: 'https://www.pochta.ru/mobilnoe-prilozhenie',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Оплата услуг и переводы',
            link: 'https://www.pochta.ru/finance',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Подписаться на газету или журнал',
            link: 'https://podpiska.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Отправить телеграмму',
            link: 'https://telegramma.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Аренда абонементного ящика',
            link: 'https://abox.pochta.ru/cabinet/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Электронные заказные письма',
            link: 'https://zakaznoe.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'ПочтаМаркет',
            link: 'https://market.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Электронная доверенность',
            link: 'https://www.pochta.ru/elektronaya-doverenost',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
        ],
      },
    ],
  },
  {
    title: 'Бизнесу',
    openInNewTab: false,
    openInNewWindow: false,
    items: [
      {
        title: 'Отправить и получить',
        openInNewTab: false,
        openInNewWindow: false,
        items: [
          {
            title: 'Личный кабинет Отправителя',
            link: 'https://otpravka.pochta.ru/?from=portalmenu',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Отправления по России',
            link: 'https://www.pochta.ru/support/parcels/inland',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Международная доставка посылок',
            link: 'https://www.pochta.ru/support/parcels/international',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Массовая отправка писем и счетов',
            link: 'https://www.pochta.ru/support/dostavka/massovaa-otpravka-pisem-i-scetov',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Электронный документооборот',
            link: 'https://edo.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Электронные заказные письма',
            link: 'https://zakaznoe.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Фулфилмент',
            link: 'https://www.pochta.ru/fulfillment',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Курьерская служба для бизнеса',
            link: 'https://www.pochta.ru/support/dostavka/courier-b2b',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Отделения для корпоративных клиентов',
            link: 'https://www.pochta.ru/support/dostavka/otdeleniya-dlya-biznesa',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Интеграция для отправки и отслеживания',
            link: 'https://www.pochta.ru/support/business/api',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Бокс-сервис',
            link: 'https://www.pochta.ru/support/corporate-services/box-service',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Бланки',
            link: 'https://www.pochta.ru/blanki',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
        ],
      },
      {
        title: 'Интернет-магазинам',
        openInNewTab: false,
        openInNewWindow: false,
        items: [
          {
            title: 'Личный кабинет Интернет-магазина',
            link: 'https://otpravka.pochta.ru/?from=Im',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Услуги для интернет-магазинов и маркетплейсов',
            link: 'https://www.pochta.ru/business-parcels/dostavka-magazinam/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Упрощенный экспорт',
            link: 'https://export.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Прием возвратов',
            link: 'https://www.pochta.ru/support/corporate-services/easy-return',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Корзинный виджет',
            link: 'https://www.pochta.ru/support/business/basket-widget',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
        ],
      },
      {
        title: 'Услуги',
        openInNewTab: false,
        openInNewWindow: false,
        items: [
          {
            title: 'Геопочта. Локальная рекламная рассылка',
            link: 'https://geo.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Таргетированная почтовая рассылка',
            link: 'https://www.pochta.ru/support/reklama/direct-mail',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Размещение рекламы в отделениях',
            link: 'https://www.pochta.ru/support/reklama/reklama-v-otdeleniyah',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Цифровая реклама на медиапанелях',
            link: 'https://www.pochta.ru/indoor',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Размещение изданий в подписном каталоге',
            link: 'https://izdatel.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Подписка на газеты и журналы',
            link: 'https://podpiska.pochta.ru/for_business',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Коммерческая недвижимость',
            link: 'https://www.pochta.ru/realty-list',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Прием платежей',
            link: 'https://www.pochta.ru/support/corporate-services/priem-platezhey',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
        ],
      },
      {
        title: 'Онлайн-сервисы',
        openInNewTab: false,
        openInNewWindow: false,
        items: [
          {
            title: 'Личный кабинет Отправка',
            link: 'https://otpravka.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Упрощенный экспорт',
            link: 'https://export.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Электронные заказные письма',
            link: 'https://zakaznoe.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Электронный документооборот',
            link: 'https://edo.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Размещение изданий в подписном каталоге',
            link: 'https://izdatel.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Подписка на газеты и журналы',
            link: 'https://podpiska.pochta.ru/for_business',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Геопочта. Рекламные рассылки',
            link: 'https://geo.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Аренда абонементного ящика',
            link: 'https://abox.pochta.ru/cabinet/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
          {
            title: 'Отслеживание отправлений через API',
            link: 'https://tracking.pochta.ru/',
            openInNewTab: false,
            openInNewWindow: false,
            items: [],
          },
        ],
      },
    ],
  },
];

export default mainNavigation;
