import React from 'react';
import { Link } from 'react-router-dom';

const ArticleContentEn = () => (
  <>
    <p>
      <strong>1. General Сonditions</strong>
    </p>
    <p>
      &nbsp;
      <br /> 1.1. FSUE «Russian Post» (Main State Registration Number 1037724007276, 37, Varshavskoye highway, 131000, Moscow, Russia, hereinafter
      refered to as Company) offers to Internet user (hereinafter refered to as User) tracking service of registered shipment (hereinafter refered to
      as Service), Internet site:&ensp;<Link to="/">https://tracking.pochta.ru/</Link>&ensp;on the conditions stated in this User Agreement
      (hereinafter refered to as «Agreement»).
      <br /> The Agreement shall come into effect upon the confirmation of the agreement with its conditions made by the User according to the
      procedure described below.
    </p>
    <p>
      <br /> 1.2. The use of the Company Service is regulated by this Agreement. Agreement may be amended by the Company without prior notice, the new
      version of the Agreement shall come into effect on the date of the publication on Internet site, indicated in this paragraph below, unless
      otherwise is stated in the new revision of User Agreement.
      <br /> User agreement as in present in force is always available here:&ensp;
      <Link to="/support/about/agreement">https://tracking.russianpost.ru/support/about/agreement</Link>.
    </p>
    <p>
      <br /> 1.3. The User is considered to have agreed to be bound by the User Agreement in its entirety and without reservation or exception once he
      starts to use the Service or some of its functions, or once he is registered to the Service. If the users disagrees with any of the conditions
      of the Agreement, the User may not use the Service of the Company. If the Company according to clause 1.2 does the amendments to the Agreement
      and the User does not agree with these amendments, he should stop using the Service of the Company.
    </p>
    <p>
      <br /> 1.4. The Service provides the User with the following functions: tracking of registered shipment with the use of software interface (API)
      in order to get access to statistics and customized notifications.
      <br />
      &nbsp;
    </p>
    <p>
      <br /> <strong>2.&nbsp;Registration of the users. User account.</strong>
      <br /> &nbsp;
      <br /> 2.1. To use the Service of the Company or its functions, the User should register to the Service. After the registration, the user
      receives access to his account.
    </p>
    <p>&nbsp;</p>
    <p>
      2.2. To use the Service of the Company, the User should sign in to his account using login and password, which he set upon the registration.
    </p>
    <p>&nbsp;</p>
    <p>
      2.3.The User is obliged to provide accurate and complete information about himself on the questions of the registration form, and to endeavor to
      keep this information up to date. If the user provides incorrect information, or there is presumption against the correctness of the provided
      information and the Company may prove it or the Company has reason to believe that the information provided by the User is incomplete or
      inaccurate, Company is entitled to block the User or delete his account and refuse the User to use the Service (or some of the functions of the
      Service).
    </p>
    <p>&nbsp;</p>
    <p>
      2.4. Once the registration of the User is confirmed, the User agrees, that the Company may use his personal data provided upon the registration
      for processing (including, but not limited to systematization, accumulation, storage, refinement, use, destruction) in order to ensure the work
      of the Service. For that purpose the company has the right to engage third parties and transfer the abovementioned data to them.
    </p>
    <p>&nbsp;</p>
    <p>
      2.5. During the process of registration, the user agrees to receive messages to the indicated e-mail address and cell phone number (including
      SMS-messages and phone calls). The user may unsubscribe from notifications any time using Service settings.
    </p>
    <p>&nbsp;</p>
    <p>
      2.6. The user is responsible for the security (guessing resistance) of his password and he ensures the confidentiality of his password on his
      own as well. When the user is signed to his account, he is responsible for all his actions (and all the consequences of these actions) during
      the use or because of the use of the Service of the Company, also when he provides data for access to his account to the third parties at his
      sole discretion under any conditions (also according to contracts, agreements, etc.)
    </p>
    <p>&nbsp;</p>
    <p>
      2.7. User is obliged to notify the Company immediately of any unauthorized access (or the access which was not authorized by the User) to the
      Service of the Company through User account and / or any password breaches (or suspected breaches). For safety reasons, the User is obliged to
      logout from his account on the site&ensp;<Link to="/">https://tracking.pochta.ru</Link>&ensp;at the end of each session (using &quot;Exit&quot;
      button). The company is not responsible for loss or damage of data, as well as other consequences of any nature that may occur due to violations
      of the conditions of this part of the User Agreement.
    </p>
    <p>&nbsp;</p>
    <p>
      <strong>3. Terms of Use of the Service of the Company</strong>
      <br /> &nbsp;
      <br /> 3.1. The Service is free of charge for the User. It supports two types of access — limited and unlimited.
    </p>
    <p>
      <br /> 3.1.1. Limited access allows any registered and authorized (according to clause 2 of the present Agreement) user to send up to 100
      requests (each request consists of 1 tracking number) per day through his information system and is available to any registered and authorized
      user according to part 2. Of the present Agreement.
    </p>
    <p>
      <br /> 3.1.2. Unlimited access is granted to the authorized representative of the legal entity, which is at the moment the Client of the Company
      as per contract for the delivery of the registered shipment. Unlimited access allows to send unlimited quantity of single and batch requests (up
      to 3000 of tracking numbers) according to specifications:&ensp;
      <Link to="/specification">https://tracking.pochta.ru/specification</Link>.
    </p>
    <p>
      <br /> 3.2. The user is solely responsible to third parties for the actions related to the use of the Service, including those, which may cause
      violation of the rights and legitimate interests of third parties, as well as compliance with the law during the use of the Service.
    </p>
    <p>
      <br /> 3.3. The user is obliged to comply with the Russian law, international Law and Code of Postal services.
    </p>
    <p>
      <br /> 3.4. The User may act on behalf of the legal entity if proper authorization is confirmed.
    </p>
    <p>
      <br /> 3.5. The User has not the right to illegally collect and store personal data of other individuals.
    </p>
    <p>
      <br /> 3.6. The User may send requests only with regard to shipments, which he is either sent or received according to the contract of the
      Company or with any branch of the Company with the legal entity, which the User represents.
    </p>
    <p>
      <br /> 3.7. The user is aware of the rules and restrictions of making requests, which are described in the present Agreement and in the
      specifications to user access protocols located here:&ensp;<Link to="/specification">https://tracking.pochta.ru/specification</Link>.
    </p>
    <p>
      <br /> 3.8. If the User violates the Terms of Use of the Service, the Company shall have the right in its sole discretion to block or delete the
      User account, suspend, limit or terminate User access to the service or deny the User to use the Service (or some of its functions), including
      the restriction to use any other accounts.
    </p>
    <p>&nbsp;</p>
  </>
);

export default ArticleContentEn;
