import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BasePage from '../base/BasePage';
import TrackingSpecification from './TrackingSpecification';

const SpecificationPage = () => {
  return (
    <BasePage showLeftMenu>
      <Switch>
        <Route path={['/specification', '/specification/single']} exact>
          <TrackingSpecification mode="single" />
        </Route>
        <Route path="/specification/batch" exact>
          <TrackingSpecification mode="batch" />
        </Route>
      </Switch>
    </BasePage>
  );
};

export default SpecificationPage;
