import React from 'react';
import { Link } from 'react-router-dom';

const SupportFaqEn = () => (
  <div className="help-page row-fluid">
    <div>
      <header>
        <h1>Support</h1>
        <p>
          If you do not find the answer to your question — please contact support by phone Russian Post <b>8-800-100-00-00</b> or email at&ensp;
          <a href="mailto:client@russianpost.ru">client@russianpost.ru</a>
        </p>
      </header>
      <article>
        <h2 id="faq">Frequently Asked Questions</h2>
        <p>
          <Link to="/support/faq/service_about">What is API for shipment tracking service?</Link>
        </p>
        <p>
          <Link to="/support/faq/service_for_who">For whom is API service convenient?</Link>
        </p>
        <p>
          <Link to="/support/faq/how_to_get_access">How to access?</Link>
        </p>
        <p>
          <Link to="/support/faq/how_to_get_full_access">How to get access to unlimited tracking?</Link>
        </p>
        <p>
          <Link to="/support/faq/no_statistics_data">Why couldn&apos;t I check statistics for the current date?</Link>
        </p>
        <p>
          <Link to="/support/faq/how_to_switch_from_old_service">I used previous version of service. How to access the new one?</Link>
        </p>
      </article>
      <footer>
        <p>If you do not find the answer to your question - please contact support by phone Russian Post</p>
        <h2>
          <b>8-800-100-00-00</b> or&ensp;<a href="mailto:client@russianpost.ru">client@russianpost.ru</a>
        </h2>
      </footer>
    </div>
  </div>
);

export default SupportFaqEn;
