import React, { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Box, Font, Ul } from '@pochta/ui';
import cn from 'classnames';
import { ICurrentMenuItem, IMenuItem } from './interfaces';
import Items from './Items';

/*
const SectionTitleLink = styled(Link)`
  /!*
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: bold;
  *!/

  color: ${({ theme }): string => theme.color.text.black.base};
  margin-left: 26px;

  ::after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    margin-top: 2px;
    margin-left: 5px;
    background-color: ${({ theme }): string => theme.palette.neutralsBlack.rgba20};
  }
`;
*/

const SectionTitle = styled(Font)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: bold;
  color: rgb(53, 57, 77);

  ::after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    margin-top: 2px;
    margin-left: 5px;
    background-color: ${({ theme }): string => theme.palette.neutralsBlack.rgba20};
  }
`;

const SectionUl = styled(Ul)`
  html.rpst-portal.aui & li {
    a {
      color: ${({ theme }): string => theme.color.text.black.base};
      margin-left: 26px;

      &.active {
        color: ${({ theme }): string => theme.color.text.link.base};
        position: relative;

        ::before {
          content: '';
          width: 8px;
          height: 8px;
          background: rgb(25, 55, 255);
          border-radius: 5px;
          position: absolute;
          top: 50%;
          margin-top: -4px;
          left: -19px;
        }
      }

      :hover {
        color: ${({ theme }): string => theme.color.text.link.hover};
        text-decoration: none;
      }
    }

    ul li {
      a {
        margin-left: 0;
        color: ${({ theme }): string => theme.color.text.black.base};
      }

      &.active a {
        color: ${({ theme }): string => theme.color.text.link.base};

        :hover {
          color: ${({ theme }): string => theme.color.text.link.hover};
          text-decoration: none;
        }
      }
    }
  }
`;

interface Props {
  section: IMenuItem;
  sectionIndex: number;
  multilevel: boolean;
  currentItem?: ICurrentMenuItem;
}

const Section: FC<Props> = ({ section, sectionIndex, multilevel, currentItem }: Props) => {
  return (
    <Box>
      {/* {section.title && <SectionTitleLink to={section.link}>{section.title}</SectionTitleLink>} */}
      {section.title && (
        <SectionTitle size="s" py={12} pl={26}>
          {section.title}
        </SectionTitle>
      )}

      <SectionUl listStyleType="none" size="m" pb={14} mx={0}>
        {section.items &&
          section.items.map((list, listIndex) => {
            const isActive = multilevel && currentItem?.sectionIndex === sectionIndex && currentItem?.listIndex === listIndex;
            return (
              <Ul.Li size="m" key={list.link} pb={8}>
                <Link to={list.link} className={cn({ active: isActive })}>
                  {list.title}
                </Link>
                {isActive && list.items && list.items.length > 0 && <Items items={list.items} currentItem={currentItem} />}
              </Ul.Li>
            );
          })}
      </SectionUl>
    </Box>
  );
};

export default memo(Section);
