import { ILeftMenuItem } from './interfaces';

const leftNavigation: ILeftMenuItem[] = [
  {
    items: [
      {
        nameKey: 'russianpost.theme.statistics',
        link: '/statistics',
        items: [
          {
            nameKey: 'left-menu.text-single-access',
            link: '/statistics/single',
            forUnlimitedUser: true,
          },
          {
            nameKey: 'left-menu.text-batch-access',
            link: '/statistics/batch',
            forUnlimitedUser: true,
          },
        ],
        forServiceTrackingUser: true,
      },
      {
        nameKey: 'russianpost.theme.accessSettings',
        link: '/access-settings',
        forServiceTrackingUser: true,
      },
      {
        nameKey: 'russianpost.theme.specification',
        link: '/specification',
        items: [
          { nameKey: 'left-menu.text-single-access', link: '/specification/single' },
          { nameKey: 'left-menu.text-batch-access', link: '/specification/batch' },
        ],
      },
    ],
  },
  {
    nameKey: 'left-menu.support',
    items: [
      {
        nameKey: 'left-menu.support.faq',
        link: '/support/faq',
        items: [
          { link: '/support/faq/service_about', nameKey: 'left-menu.support.faq.service_about' },
          { link: '/support/faq/service_for_who', nameKey: 'left-menu.support.faq.service_for_who' },
          { link: '/support/faq/how_to_get_access', nameKey: 'left-menu.support.faq.how_to_get_access' },
          { link: '/support/faq/how_to_get_full_access', nameKey: 'left-menu.support.faq.how_to_get_full_access' },
          { link: '/support/faq/no_statistics_data', nameKey: 'left-menu.support.faq.no_statistics_data' },
          { link: '/support/faq/how_to_switch_from_old_service', nameKey: 'left-menu.support.faq.how_to_switch_from_old_service' },
        ],
      },
      {
        nameKey: 'left-menu.support.about',
        link: '/support/about',
        items: [
          { link: '/support/about/agreement', nameKey: 'left-menu.support.about.agreement' },
          { link: '/support/about/examples', nameKey: 'left-menu.support.about.examples' },
          { link: '/support/about/testing_access', nameKey: 'left-menu.support.about.testing_access' },
        ],
      },
      {
        nameKey: 'left-menu.support.dictionaries',
        link: '/support/dictionaries',
        items: [
          { link: '/support/dictionaries/operation_codes', nameKey: 'left-menu.support.dictionaries.operation_codes' },
          { link: '/support/dictionaries/category_codes', nameKey: 'left-menu.support.dictionaries.category_codes' },
          { link: '/support/dictionaries/mailrank', nameKey: 'left-menu.support.dictionaries.mailrank' },
          { link: '/support/dictionaries/mailtype', nameKey: 'left-menu.support.dictionaries.mailtype' },
          { link: '/support/dictionaries/postmark', nameKey: 'left-menu.support.dictionaries.postmark' },
          { link: '/support/dictionaries/countries', nameKey: 'left-menu.support.dictionaries.countries' },
          { link: '/support/dictionaries/send_ctg', nameKey: 'left-menu.support.dictionaries.send_ctg' },
          { link: '/support/dictionaries/event_type', nameKey: 'left-menu.support.dictionaries.event_type' },
          { link: '/support/dictionaries/special-termins', nameKey: 'left-menu.support.dictionaries.special-termins' },
        ],
      },
    ],
  },
];

export default leftNavigation;
