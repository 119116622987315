import './styles.scss';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Box, PageContainer } from '@pochta/ui';
import Footer from './footer/Footer';
import { Notification } from '../../components/Notification';
import useCommonContext from '../../hook/useCommonContext';
import { urls } from '../../service/constants';
import mainNavigation from './menu/mainNavigation';
import LeftMenu from './menu/LeftMenu';

const { Header } = require('@portal/portal-theme-v2');

type Props = {
  showLeftMenu?: boolean;
};

const BasePage = ({ showLeftMenu, children }: PropsWithChildren<Props>) => {
  const { t } = useTranslation<string>();
  const { userInfo, isShowNotification, message, closeNotification } = useCommonContext();

  const onClickPostIdLogin = () => {
    window.location.href = urls.LOGIN_URL;
  };

  return (
    <div className={cn('white-footer', 'controls-visible', 'guest-site', 'signed-in', 'public-page', 'site')}>
      <div className="header-container">
        <Header
          menu={mainNavigation}
          authorized={userInfo.isAuthorized}
          userId={userInfo.hid}
          userName={userInfo.name}
          onClickPostIdLogin={onClickPostIdLogin}
          logoUrl="/"
          postIdLogoutUrl={urls.LOGOUT_URL}
          postIdUserAccountUrl={urls.USER_ACCOUNT_URL}
        />
      </div>
      <div className="tracking-page">
        {showLeftMenu && (
          <PageContainer>
            <Box flexbox mx={-26} pt={38} pb={90}>
              <LeftMenu />
              {children || t('Welcome to BasePage')}
            </Box>
          </PageContainer>
        )}
        {!showLeftMenu && (children || t('Welcome to BasePage'))}
        <Notification show={isShowNotification} message={message} onClose={closeNotification} />
      </div>
      <Footer />
    </div>
  );
};

export default BasePage;
