import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BasePage from '../base/BasePage';
import StatisticsForm from './StatisticsForm';

const StatisticsPage = () => (
  <BasePage showLeftMenu>
    <Switch>
      <Route path={['/statistics', '/statistics/single']} exact>
        <StatisticsForm mode="single" />
      </Route>
      <Route path="/statistics/batch" exact>
        <StatisticsForm mode="batch" />
      </Route>
    </Switch>
  </BasePage>
);

export default StatisticsPage;
