import React from 'react';
import BasePage from '../base/BasePage';
import TrackingSettings from './TrackingSettings';

const SettingsPage = () => {
  return (
    <BasePage showLeftMenu>
      <TrackingSettings />
    </BasePage>
  );
};

export default SettingsPage;
