import React from 'react';
import { useTranslation } from 'react-i18next';
import BatchRu from './BatchRu';
import BatchEn from './BatchEn';
import SingleRu from './SingleRu';
import SingleEn from './SingleEn';
import { ModeType } from '../../service/api-dtos';

type Props = {
  mode?: ModeType;
};

const TrackingSpecification = ({ mode = 'single' }: Props) => {
  const { i18n } = useTranslation<string>();
  return (
    <div className="specification-page">
      {mode === 'single' && i18n.language === 'ru' && <SingleRu />}
      {mode === 'single' && i18n.language === 'en' && <SingleEn />}
      {mode === 'batch' && i18n.language === 'ru' && <BatchRu />}
      {mode === 'batch' && i18n.language === 'en' && <BatchEn />}
    </div>
  );
};

export default TrackingSpecification;
