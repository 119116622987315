import React from 'react';
import { Link } from 'react-router-dom';

const SupportAboutRu = () => (
  <div className="help-page row-fluid">
    <div>
      <header>
        <h1>Помощь</h1>
        <p>
          Если вы не нашли ответа на свой вопрос - обратитесь в службу поддержки Почты России по телефону <b>8-800-100-00-00</b> или напишите на&ensp;
          <a href="mailto:client@russianpost.ru?subject=Отслеживание отправлений через API">client@russianpost.ru</a>
        </p>
      </header>
      <article>
        <h2 id="about">О сервисе</h2>
        <p>
          <Link to="/support/about/agreement">Пользовательское соглашение</Link>
        </p>
        <p>
          <Link to="/support/about/examples">Примеры кода для работы с API</Link>
        </p>
        <p>
          <Link to="/support/about/testing_access">Как проверить доступ</Link>
        </p>
      </article>
      <footer>
        <p>Если вы не нашли ответа на свой вопрос — обратитесь в службу поддержки Почты России</p>
        <h2>
          <b>8-800-100-00-00</b> или&ensp;
          <a href="mailto:client@russianpost.ru?subject=Отслеживание отправлений через API">client@russianpost.ru</a>
        </h2>
      </footer>
    </div>
  </div>
);

export default SupportAboutRu;
