import React from 'react';
import { Link } from 'react-router-dom';

const SupportDictionariesEn = () => (
  <div className="help-page row-fluid">
    <div>
      <header>
        <h1>Support</h1>
        <p>
          If you do not find the answer to your question — please contact support by phone Russian Post <b>8-800-100-00-00</b> or email at&ensp;
          <a href="mailto:client@russianpost.ru">client@russianpost.ru</a>
        </p>
      </header>
      <article>
        <h2 id="dictionaries">Technical references</h2>
        <p>
          <Link to="/support/dictionaries/operation_codes">Operation codes and operation attributes</Link>
        </p>
        <p>
          <Link to="/support/dictionaries/category_codes">Mail category codes</Link>
        </p>
        <p>
          <Link to="/support/dictionaries/mailrank">Mail rank codes</Link>
        </p>
        <p>
          <Link to="/support/dictionaries/mailtype">Mail type codes</Link>
        </p>
        <p>
          <Link to="/support/dictionaries/postmark">Mail mark codes</Link>
        </p>
        <p>
          <Link to="/support/dictionaries/countries">Country directory</Link>
        </p>
        <p>
          <Link to="/support/dictionaries/send_ctg">Sender Category Codes</Link>
        </p>
        <p>
          <Link to="/support/dictionaries/event_type">Codes of cash-on-delivery (COD) operations</Link>
        </p>
        <p>
          <Link to="/support/dictionaries/special-termins">Technical terms</Link>
        </p>
      </article>
      <footer>
        <p>If you do not find the answer to your question - please contact support by phone Russian Post</p>
        <h2>
          <b>8-800-100-00-00</b> or&ensp;<a href="mailto:client@russianpost.ru">client@russianpost.ru</a>
        </h2>
      </footer>
    </div>
  </div>
);

export default SupportDictionariesEn;
